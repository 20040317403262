import { render, h } from "vue";
import VToasts from "~/components/VToasts/VToasts.vue";
import type { IToast } from "~/components/VToasts/VToasts.types";

const mount = (props: IToast) => {
  const parentElement =
    typeof document !== "undefined" && document.createElement("div");
  if (!parentElement) {
    return;
  }
  const vNode = h(VToasts, props);
  render(vNode, parentElement);
};

const useToasts = () => {
  return {
    success(props: Omit<IToast, "type">) {
      mount({
        ...props,
        type: "success",
      });
    },
    warning(props: Omit<IToast, "type">) {
      mount({
        ...props,
        type: "warning",
      });
    },
  };
};

export default useToasts;
